import { API_URL } from 'constants/env'

export const getGoogleOAuthRedirectUrl = (): URL => {
  const url = `${API_URL}/google/auth/redirect`
  return new URL(url)
}

export const getGoogleAnalyticsSelectPropertyUrl = (): URL => {
  const url = `${API_URL}/google/analytics/selectProperty`
  return new URL(url)
}

export const getGoogleAnalyticsGetPropertyUrl = (): URL => {
  const url = `${API_URL}/google/analytics/getProperty`
  return new URL(url)
}
