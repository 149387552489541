import { axiosExtended } from 'api/axios'

import {
  SelectPropertyParams,
  SelectPropertyResponse,
} from './selectProperty.types'
import { getGoogleAnalyticsSelectPropertyUrl } from '../urls'

export const selectProperty = async (data: SelectPropertyParams) => {
  const { href } = getGoogleAnalyticsSelectPropertyUrl()
  const response = await axiosExtended.post<SelectPropertyResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
