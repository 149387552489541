import { axiosExtended } from 'api/axios'

import { GetPropertyParams, GetPropertyResponse } from './getProperty.types'
import { getGoogleAnalyticsGetPropertyUrl } from '../urls'

export const getProperty = async (data: GetPropertyParams) => {
  const { href } = getGoogleAnalyticsGetPropertyUrl()
  const response = await axiosExtended.post<GetPropertyResponse>(href, data, {
    withAuth: true,
  })
  return response.data
}
