import { API_URL } from 'constants/env'

export const getCreateConversationUrl = (): URL => {
  const url = `${API_URL}/conversation/create`
  return new URL(url)
}

export const getSendMessageToConversationUrl = (): URL => {
  const url = `${API_URL}/conversation/newMessage`
  return new URL(url)
}

export const getGetConversationsUrl = (): URL => {
  const url = `${API_URL}/conversation/list`
  return new URL(url)
}

export const getGetMessagesUrl = (): URL => {
  const url = `${API_URL}/conversation/messages`
  return new URL(url)
}

export const getGetQueryMessagesUrl = (): URL => {
  const url = `${API_URL}/conversation/queryMessages`
  return new URL(url)
}

export const upvoteResponseUrl = (): URL => {
  const url = `${API_URL}/conversation/upvoteMessage`
  return new URL(url)
}
