export enum IntegrationStatus {
  not_started = 'not_started',
  connecting = 'connecting',
  creating_db = 'creating_db',
  replicating_data = 'replicating_data',
  generating_insights = 'generating_insights',
  completed = 'completed',
  failed = 'failed',
}

export const IntegrationStatusLabel = {
  [IntegrationStatus.not_started]: 'Not started',
  [IntegrationStatus.connecting]: 'Connecting...',
  [IntegrationStatus.creating_db]: 'Creating DB...',
  [IntegrationStatus.replicating_data]: 'Replicating data...',
  [IntegrationStatus.generating_insights]: 'Generating insights...',
  [IntegrationStatus.completed]: 'Completed',
  [IntegrationStatus.failed]: 'Failed',
}
